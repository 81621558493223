var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('div',{staticClass:"export-download"},[(_vm.isView)?_c('div',{staticClass:"button",on:{"click":_vm.exportForm}},[_c('img',{staticClass:"export",attrs:{"src":_vm.exportImage,"alt":""}}),_c('span',[_vm._v("导出文档")])]):_vm._e(),_c('div',{staticClass:"button",on:{"click":_vm.download}},[_c('img',{staticClass:"download",attrs:{"src":_vm.downloadImage,"alt":""}}),_c('span',[_vm._v("下载模板")])])])]},proxy:true}])},[_c('a-tabs',{attrs:{"defaultActiveKey":"0","tabBarStyle":{
        margin: '0',
      }}},[_c('a-tab-pane',{key:"0",attrs:{"tab":_vm.stageName}})],1)],1),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.designCode}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"标段名称","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bidName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'bidName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"date",attrs:{"label":"送达时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'fileArrivalDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'fileArrivalDate',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","format":"YYYY-MM-DD HH:mm:ss"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"文件寄送地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'fileSendAddr',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'fileSendAddr',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"受邀报价单位"}},[_c('SupplierSeletor',{attrs:{"msg":_vm.selectedCompany},on:{"change":(company) => (_vm.selectedCompany = company)}},[_c('a-button',{staticStyle:{"text-align":"left","min-height":"32px","height":"auto"},attrs:{"block":""}},[(_vm.selectedCompany.length > 0)?_c('div',{staticClass:"wrap"},_vm._l((_vm.selectedCompany),function(item,index){return _c('span',{key:index,staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(`${item.company} `)+" ")])}),0):_c('div',{staticStyle:{"color":"#1890ff"}},[_vm._v("请选择")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contact',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'contact',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticStyle:{"padding":"0 50px"},attrs:{"wrapper-col":{ span: 24 }}},[_c('Table',{attrs:{"value":_vm.initTable},on:{"getData":_vm.getData}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"支付方式","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'payMethod',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'payMethod',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"供货周期","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'deliveryCycle',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'deliveryCycle',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"售后及其他要求","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'afterSalesRemark',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'afterSalesRemark',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'file',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请上传附件！',
                      },
                    ],
                  },
                ]),expression:"[\n                  'file',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '请上传附件！',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 《采购询价函》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }