<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs defaultActiveKey="0"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0"
                    :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button"
               @click="exportForm"
               v-if="isView">
            <img class="export"
                 :src="exportImage"
                 alt="" />
            <span>导出文档</span>
          </div>
          <div class="button"
               @click="download">
            <img class="download"
                 :src="downloadImage"
                 alt="" />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
              :colon="false">
        <a-row>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="当前项目"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="工程名称"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="projectName" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="标段名称"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input v-decorator="[
                  'bidName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="送达时间"
                         class="date">
              <a-date-picker placeholder="请选择"
                             v-decorator="[
                  'fileArrivalDate',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                             style="width: 100%"
                             format="YYYY-MM-DD HH:mm:ss" />
            </a-form-item>
          </a-col>

          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="文件寄送地址">
              <a-input v-decorator="[
                  'fileSendAddr',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="受邀报价单位">
              <SupplierSeletor @change="(company) => (selectedCompany = company)"
                               :msg='selectedCompany'>
                <a-button block
                          style="text-align: left; min-height:32px; height:auto;">
                  <div v-if="selectedCompany.length > 0"
                       class="wrap">
                    <span v-for="(item, index) in selectedCompany"
                          :key="index"
                          style="margin-right:5px;">
                      {{ `${item.company} ` }}
                    </span>
                  </div>
                  <div v-else
                       style="color: #1890ff;">请选择</div>
                </a-button>
              </SupplierSeletor>
            </a-form-item>
          </a-col>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="联系人">
              <a-input v-decorator="[
                  'contact',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item style="padding:0 50px;"
                         :wrapper-col="{ span: 24 }">
              <Table @getData="getData"
                     :value="initTable" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="支付方式"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input v-decorator="[
                  'payMethod',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="供货周期"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input v-decorator="[
                  'deliveryCycle',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="售后及其他要求"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input v-decorator="[
                  'afterSalesRemark',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <span slot="label"
                    class="">附件</span>
              <div class="link-list"
                   v-decorator="[
                    'file',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请上传附件！',
                        },
                      ],
                    },
                  ]">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile"
                                  v-if="fileList.length !== 0">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div style="color:#ccc;"
                     v-else-if="fileList.length === 0 && id">
                  无
                </div>
                <div style="color:#ccc;"
                     v-else>
                  《采购询价函》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import Table from "./components/table";
import SupplierSeletor from "@/components/supplier-selector";

import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";
import moment from "moment";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  name: "purchaseInq",
  components: {
    SupplierSeletor,
    Table,
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_purchase_inquiry",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid: "",
      projectName: "",
      designCode: "",
      tableList: [], //表格修改值
      initTable: [], //表格初始值

      fileList: [],
      selectedFileList: [],

      selectedCompany: [], //受邀报价单位
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.form.setFieldsValue({
            bidName: res.bidName,
            fileArrivalDate: res.fileArrivalDate,
            fileSendAddr: res.fileSendAddr,
            contact: res.contact,
            payMethod: res.payMethod,
            deliveryCycle: res.deliveryCycle,
            afterSalesRemark: res.afterSalesRemark,
          });

          this.selectedCompany = res.invitedCompanyList.map((item) => {
            return {
              company: item.name,
              id: item.id,
            };
          });
          this.initTable = [...res.planList];
          this.tableList = [...res.planList];

          if (res.attachmentList) {
            this.form.setFieldsValue({
              file: true,
            });
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    getData(value) {
      this.tableList = value;
      console.log(this.tableList);
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;

          // 添加projectId等字段
          const planList = this.tableList.map((item) => {
            const { count, name, remark, techDesc } = item;
            if (this.id) {
              return {
                id: item.id,
                pid: this.id,
                count,
                name,
                remark,
                techDesc,
                projectId: this.pid,
                designCode: this.designCode,
              };
            } else {
              return {
                count,
                name,
                remark,
                techDesc,
                projectId: this.pid,
                designCode: this.designCode,
              };
            }
          });

          console.log(planList);
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              planList,
              type: this.stage,
              fileArrivalDate: moment(values.fileArrivalDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              invitedCompanyList: this.selectedCompany.map((item) => {
                return {
                  name: item.company,
                  id: item.id,
                };
              }),
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
              this.selectedCompany = [];
            });
          } else {
            // 删除的添加delete
            this.initTable.forEach((item) => {
              let index = planList.findIndex((ele) => ele.id === item.id);
              if (index < 0) {
                item.delete = 1;
              }
            });
            planList.push(
              ...this.initTable.filter((item) => item.delete === 1)
            );
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              fileArrivalDate: moment(values.fileArrivalDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              planList,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
