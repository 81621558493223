<template>
  <div class="table">
    <a-table :dataSource="list"
             :pagination="false"
             bordered
             :locale="locale">
      <template #title>
        <div class="title"> 拟采购货物一览表 </div>
      </template>
      <a-table-column title="序号"
                      width="50px"
                      align="center"
                      data-index="key">
        <template slot-scope="text">
          <div>
            {{text}}
          </div>
        </template>
      </a-table-column>

      <a-table-column title="货物名称"
                      align="center"
                      data-index="name">
        <template slot-scope="text, record">
          <a-input style="margin: -5px 0"
                   v-model="record.name"
                   :placeholder="'请输入'"
                   @change="onInputChange" />
        </template>
      </a-table-column>

      <a-table-column title="型号规格、主要技术参数及标准配置"
                      align="center"
                      data-index="techDesc">
        <template slot-scope="text, record">
          <a-input style="margin: -5px 0"
                   v-model="record.techDesc"
                   :placeholder="'请输入'"
                   @change="onInputChange" />
        </template>
      </a-table-column>

      <a-table-column title="数量"
                      align="center"
                      data-index="count">
        <template slot-scope="text, record">
          <a-input style="margin: -5px 0"
                   v-model="record.count"
                   :placeholder="'请输入'"
                   @change="onInputChange" />
        </template>
      </a-table-column>

      <a-table-column title="备注"
                      align="center"
                      data-index="remark">
        <template slot-scope="text, record">
          <a-input style="margin: -5px 0"
                   v-model="record.remark"
                   :placeholder="'请输入'"
                   @change="onInputChange" />
        </template>
      </a-table-column>

      <a-table-column title="操作"
                      align="center">
        <template slot-scope="text, record">
          <a-popconfirm title="是否要删除此行？"
                        @confirm="removeMember(record)">
            <a>删除</a>
          </a-popconfirm>
        </template>
      </a-table-column>

      <template slot="operation"
                slot-scope="text, record">
        <a-popconfirm title="是否要删除此行？"
                      @confirm="removeMember(record)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-button style="width:100%"
              type="primary"
              @click="addtable()">点击增加行</a-button>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(val) {
      console.log(val);
      this.list = val;
      this.list.forEach((item, index) => {
        item.key = index + 1;
      });
    },
  },
  data() {
    return {
      list: [],
      locale: { emptyText: " " }, //设置空数据的展示
    };
  },
  methods: {
    addtable() {
      const length = this.list.length;

      this.list.push({
        key: length + 1,
        name: "",
        techDesc: "",
        count: "",
        remark: "",
      });
    },
    onInputChange() {
      console.log(this.list);
      this.$emit("getData", this.list);
    },

    removeMember(row) {
      console.log(this.list);
      const key = row.key - 1;
      this.list.splice(key, 1);
      this.$emit("getData", this.list);
    },
  },
};
</script>
<style lang="less" scoped>
.table {
  padding: 0 50px;
  .title {
    text-align: center;
    padding: 5px;
    font-size: 1.2em;
    font-weight: bolder;
  }
}
</style>
